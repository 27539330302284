import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loading from './components/utils/Loading';
import FallbackLocalStorage from "fallback-local-storage";
import { ref, onValue, off } from 'firebase/database';
import {db} from "./base";

const MainAppLazy = lazy(() => import('./components/main_screens/main_app'));
const LoginLazy = lazy(() => import('./components/main_screens/login'));
const RedeemLazy = lazy(() => import('./components/main_screens/RedeemedViewer'));
const AgeVerificationLazy = lazy(() => import('./components/main_screens/AgeVerification'));
const BigScreenLazy = lazy(() => import('./components/main_screens/BigScreen'));
const SideBigScreenLazy = lazy(() => import('./components/main_screens/SideBigScreen'));

function AuthenticatedRoute({ children, authenticated, redirectTo }) {
    return authenticated ? children : <Navigate to={redirectTo} />;
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        this.checkForAgeGate = this.checkForAgeGate.bind(this)
        let userEmail;
        try {
            userEmail = this.state.appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false,
            appStorage: null,
            language: "en"  // Default language set to English
        }
        this.tenantVariablesRef = null; // Reference for unsubscribing
    }

    setCurrentUser() {
        const userEmail = this.state.appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        let appStorage;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = window.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = window.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
        return appStorage;
    }

    componentDidMount() {
      this.getTenantVariables();
    }

    getTenantVariables(){
        let splitWindowLocation = window.location.pathname.split('/');
        let language = "english"; // Default language
        if (splitWindowLocation.length > 2) {
            language = splitWindowLocation[2];
        } else if (splitWindowLocation.length > 1) {
            language = splitWindowLocation[1];
        }
        this.loadLanguage(language);

        const tenantVariablesRef = ref(db, 'tenantVariables');
        this.tenantVariablesRef = tenantVariablesRef; // Save the reference for cleanup
        onValue(tenantVariablesRef, (snapshot) => {
            const variables = snapshot.val();
            if (variables) {
                document.title = variables.pageTitle || "Trivia";
                const appStorage = this.setUpStorage(variables);
                this.setState({
                    variables: variables,
                    loading: false,
                    language: language,
                    appStorage: appStorage
                });
            }
        });
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = this.state.appStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

    async loadLanguage(language) {
        let languageConstants;
        try {
            languageConstants = await import(`./constants/languages/${language}.js`);
        } catch (e) {
            language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";  // Fallback to English or default environment language
            languageConstants = await import(`./constants/languages/${language}.js`);
        }
        this.setState({
            languageConstants
        });
    }

    componentWillUnmount() {
        if (this.tenantVariablesRef) {
            off(this.tenantVariablesRef); // Unsubscribe from changes
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading loading={this.state.loading} />;
        }
        let redirectUrl = `/login`;
        if (this.state.language) {
            redirectUrl += ("/" + this.state.language);
        }
        const appStorage = this.state.appStorage;
        return (
            <BrowserRouter>
                <Suspense fallback={<Loading loading={this.state.loading} />}>
                    <Routes>
                        <Route path="/redeemviewer" element={<RedeemLazy stringConstants={this.state.languageConstants} tenantVariables={this.state.variables} />}/>
                        <Route path="/login" element={<LoginLazy appStorage={appStorage} authenticated={this.state.authenticated} setCurrentUser={this.setCurrentUser} stringConstants={this.state.languageConstants} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} />} />
                        <Route path="/bigscreen" element={<BigScreenLazy variables={this.state.variables} />} />
                        <Route path="/sidebigscreen" element={<SideBigScreenLazy variables={this.state.variables} />} />
                        <Route path="/age_gate" element={<AgeVerificationLazy appStorage={appStorage} stringConstants={this.state.languageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} />} />
                        <Route path="/" element={
                            <AuthenticatedRoute authenticated={this.state.authenticated} redirectTo={redirectUrl}>
                                <MainAppLazy variables={this.state.variables} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} stringConstants={this.state.languageConstants} appStorage={appStorage} />
                            </AuthenticatedRoute>
                        } />
                        <Route path="*" element={<Navigate to={redirectUrl} />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        )
    }
}

export default App;
